.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(235, 245, 251);
  min-height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiMenu-paper {
  min-width: unset !important;
  max-width: 300px !important;
}

.MuiSelect-select {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.App-main a {
  text-decoration: none;
}

.cocktail-card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
